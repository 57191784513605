<template>
  <el-container>
    <div class="set-box">
      <div class="account">
        <span>账号：{{ userInfo.u_account }}</span>
        <span class="update" @click="is_update = !0">修改密码</span>
      </div>
      <div class="name">
        <span>姓名：</span>
        <el-input size="small" v-model="name"></el-input>
      </div>
      <div class="img">
        <span>头像：</span>
        <el-input v-model="logo" :disabled="true" size="small">
          <el-button slot="append" @click="showImgLibrary = !0" icon="el-icon-upload"></el-button>
        </el-input>
      </div>
      <div class="logo" v-if="logo">
        <img :src="logo" alt="" />
      </div>
      <div class="btn" style="padding-left: 42px">
        <el-button style="margin-right: 10px" @click="updateInfo" type="primary" size="small">确认修改</el-button>
        <router-link to="/shop">
          <el-button size="small">返回</el-button>
        </router-link>
      </div>
    </div>
    <el-dialog title="修改密码" :visible.sync="is_update" width="35%">
      <div class="password">
        <span>新密码：</span>
        <el-input v-model="password1"></el-input>
      </div>
      <div class="password">
        <span>再次新密码：</span>
        <el-input v-model="password2"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="is_update = !1">取 消</el-button>
        <el-button type="primary" @click="updatePassword">确 定</el-button>
      </span>
    </el-dialog>
    <ImgLibrary v-if="showImgLibrary" :url="1" @changeStatus="changeStatus" :dialogVisible="showImgLibrary" @getImg="getImg"></ImgLibrary>
  </el-container>
</template>

<script>
import ImgLibrary from '@/components/imgLibrary';
export default {
  components: {
    ImgLibrary,
  },
  data() {
    return {
      is_update: !1,
      showImgLibrary: !1,
      password1: '',
      password2: '',
      name: '',
      logo: '',
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  created() {
    this.name = this.userInfo.name;
    this.logo = this.userInfo.avatar || '';
  },
  methods: {
    updatePassword() {
      if (!this.password1) return this.$message.error('请填写新密码');
      if (!this.password2) return this.$message.error('请再次填写新密码');
      this.$axios
        .post(this.$api.user.editPassword, {
          u_password: this.password1,
          re_u_password: this.password2,
        })
        .then((res) => {
          if (res.code == 0) {
            this.is_update = !1;
            this.password1 = '';
            this.password2 = '';
            this.$message.success('密码修改成功');
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    changeStatus(val) {
      this.showImgLibrary = val;
    },
    getImg(val) {
      this.showImgLibrary = !1;
      if (val.length) this.logo = val[0].path;
    },
    updateInfo() {
      console.log(this.userInfo);
      if (!this.name) return this.$message.error('请填写姓名');
      if (!this.logo) return this.$message.error('请上传头像');
      this.$axios
        .post(this.$api.user.update, {
          name: this.name,
          avatar: this.logo,
        })
        .then((res) => {
          if (res.code == 0) {
            this.$message({
              message: '修改成功',
              type: 'success',
            });
            this.$store.dispatch('user/getUserInfo');
            this.$router.push('/shop');
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less">
.el-container {
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  .set-box {
    width: 950px;
    padding: 30px 0;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    .account,
    .name,
    .img,
    .logo,
    .btn {
      width: 550px;
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      .update {
        margin-left: 15px;
        color: #39f;
        cursor: pointer;
      }
      .el-input {
        flex: 1;
      }
      img {
        width: 80px;
        height: 80px;
        border: 1px solid #f4f4f4;
        margin-left: 42px;
      }
    }
  }
}
.password {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  span {
    width: 100px;
    text-align: right;
  }
  .el-input {
    flex: 1;
  }
}
</style>
